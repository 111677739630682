import React, { useEffect } from "react";
import { Link } from "gatsby";
import appData from "data/app.json";
import { handleDropdown, handleMobileDropdown } from "common/navbar";


const Navbar = ({ lr, nr, theme }) => {

  useEffect(() => {

    function callDropDown(e){
      handleDropdown(e);
    }
   
    function removeCustomClasses(){

      if(window.innerWidth <= 991)
        {
          
          document.querySelectorAll('.dropdown-menu').forEach((element) => {
            element.classList.remove('custom-dropdown-menu');
            let navitems = element.children;
            Array.from(navitems).forEach((item) => {
              item.classList.remove('custom-dropdown-item');
              
            })
          })
  
          document.querySelectorAll('.custom-nav-link').forEach((item) => {
            item.addEventListener('click',callDropDown);
          })
        }
        else
        {
          document.querySelectorAll('.dropdown-menu').forEach((element) => {
            element.classList.add('custom-dropdown-menu');

            let navitems = element.children;
            Array.from(navitems).forEach((item) => {
              item.classList.add('custom-dropdown-item');
              
            })

            // element.parentElement.addEventListener("mouseover",() => {
            //   console.log(element.parentElement.children[1])
            //   element.parentElement.children[1].style.visibility="visible";
            //   element.parentElement.children[1].style.opacity="1";

            // })

            // element.parentElement.addEventListener("mouseout",() => {
            //   console.log(element.parentElement.children[1])
            //   element.parentElement.children[1].style.visibility="hidden";
            //   element.parentElement.children[1].style.opacity="0";

            // })

            // element.addEventListener("mouseover",() => {
            //   console.log(element.parentElement.children[0]);
            //   element.parentElement.children[0].style.visibility="visible";
            //   element.parentElement.children[0].style.opacity="1";
               
            // })

            // element.addEventListener("mouseout",() => {
            //   console.log(element.parentElement.children[0]);
            //   element.parentElement.children[0].style.visibility="hidden";
            //   element.parentElement.children[0].style.opacity="0";
               
            // })
          })
  
          document.querySelectorAll('.custom-nav-link').forEach((item) => {
            item.removeEventListener('click',callDropDown);
          })
        }
    }

    removeCustomClasses();

    window.onresize = () => removeCustomClasses();
   
  }, [])



  return (
    <nav
      ref={nr}
      className={`navbar navbar-expand-lg change ${
        theme === "themeL" ? "light" : ""
      }`}
    >
      <div className="container">
        <Link to="/" className="logo ">
          {theme ? (
            theme === "themeL" ? (
              <img ref={lr} src={appData.darkLogo} alt="logo" className="img-home"/>
            ) : (
              <img ref={lr} src={appData.lightLogo} alt="logo" className="img-home"/>
            )
          ) : (
            <img ref={lr} src={appData.lightLogo} alt="logo" className="img-home"/>
          )}
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={handleMobileDropdown}
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown custom-nav-link">
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                
              >
                Solutions
              </span>
              <div className="dropdown-menu custom-mobile-menu">
                <Link to={`/elf/it-consulting-services`} className="dropdown-item custom-dropdown-item">
                  IT Consulting
                </Link>
                <Link to={`/elf/esdm`} className="dropdown-item custom-dropdown-item">
                  Electronics System Design & Manufacturing
                </Link>
                <Link to={`/elf/cscs`} className="dropdown-item custom-dropdown-item">
                  Connected Supply Chain Services
                </Link>
                <Link to={`/elf/product`} className="dropdown-item custom-dropdown-item">
                  Product Solutions
                </Link>
                {/* <Link to={`/elf/design-digital`} className="dropdown-item custom-dropdown-item">
                  Digital Studio
                </Link> */}
                <Link to={`/elf/staff`} className="dropdown-item custom-dropdown-item">
                  Staff Augmentation
                </Link>
              </div>
              {/* <div className="dropdown-menu">
                <Link to={`/homepage/home1-dark`} className="dropdown-item">
                  Main Home
                </Link>
                <Link to={`/homepage/home2-dark`} className="dropdown-item">
                  Creative Agency
                </Link>
                <Link to={`/homepage/home5-dark`} className="dropdown-item">
                  Digital Agency
                </Link>
                <Link to={`/homepage/home4-dark`} className="dropdown-item">
                  Business One Page
                </Link>
                <Link to={`/homepage/home3-dark`} className="dropdown-item">
                  Corporate Business
                </Link>
                <Link to={`/homepage/home6-dark`} className="dropdown-item">
                  Modern Agency
                </Link>
                <Link to={`/homepage/home7-dark`} className="dropdown-item">
                  Freelancer
                </Link>
                <Link to={`/homepage/home8-dark`} className="dropdown-item">
                  Architecture
                </Link>
              </div> */}
            </li>
            <li className="nav-item">
              <Link to={`/elf/about-us`} className="nav-link">
                About Us
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to={`/about/about-dark`} className="nav-link">
                About
              </Link>
            </li>
            <li className="nav-item dropdown" onClick={handleDropdown}>
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Insights
              </span>
              <div className="dropdown-menu">
                <Link to={`/elf/insights/media-coverage`} className="dropdown-item">
                  Media Coverage
                </Link>
                <Link to={`/elf/insights/blogs`} className="dropdown-item">
                  Blogs
                </Link>

              </div>
            </li>*/}
            <li className="nav-item dropdown custom-nav-link">
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                
              >
                Buzz & Chronicles
              </span>
              <div className="dropdown-menu custom-mobile-menu">
                <Link to={`/elf/buzz-and-chronicles/media-coverage`} className="dropdown-item custom-dropdown-item">
                  Media Coverage
                </Link>
                <Link to={`/elf/buzz-and-chronicles/insider`} className="dropdown-item custom-dropdown-item">
                  Insider
                </Link>
                <Link to={`/elf/buzz-and-chronicles/sports-section`} className="dropdown-item custom-dropdown-item">
                  Sports
                </Link>
                <Link to={`/elf/buzz-and-chronicles/blogs`} className="dropdown-item custom-dropdown-item">
                  Blogs
                </Link>
              
              </div>
            </li>
            <li className="nav-item dropdown custom-nav-link">
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                
              >
                Our Team
              </span>
              <div className="dropdown-menu custom-mobile-menu">
                <Link to={`/elf/our-team/founder`} className="dropdown-item custom-dropdown-item">
                  Founders & C-suite
                </Link>
                <Link to={`/elf/our-team/team`} className="dropdown-item custom-dropdown-item">
                  Executive Heads
                </Link>

              </div>
            </li>
            {/* <li className="nav-item dropdown" onClick={handleDropdown}>
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Works
              </span>
              <div className="dropdown-menu">
                <Link to={`/showcase/showcase-dark`} className="dropdown-item">
                  Showcase Parallax
                </Link>
                <Link to={`/showcase4/showcase4-dark`} className="dropdown-item">
                  Showcase Carousel
                </Link>
                <Link to={`/showcase3/showcase3-dark`} className="dropdown-item">
                  Showcase Circle
                </Link>
                <Link to={`/works/works-dark`} className="dropdown-item">
                  Portfolio Masonry
                </Link>
                <Link to={`/works2/works2-dark`} className="dropdown-item">
                  Portfolio Filtering
                </Link>
                <Link to={`/works3/works3-dark`} className="dropdown-item">
                  Portfolio Gallery
                </Link>
              </div>
            </li> */}
            <li className="nav-item">
              <Link to='https://elfonze.zohorecruit.in/jobs/Careers' className="nav-link" target="_blank">
                Careers
              </Link>
            </li>
            {/* <li className="nav-item dropdown" onClick={handleDropdown}>
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Blog
              </span>
              <div className="dropdown-menu">
                <Link to={`/blog/blog-dark`} className="dropdown-item">
                  Blog Standerd
                </Link>
                <Link to={`/blog-list/blog-list-dark`} className="dropdown-item">
                  Blog List
                </Link>
                <Link to={`/blog-grid/blog-grid-dark`} className="dropdown-item">
                  Blog Grid
                </Link>
                <Link to={`/blog-details/blog-details-dark`} className="dropdown-item">
                  Blog Details
                </Link>
              </div>
            </li> */}
            <li className="nav-item">
              <Link to={`/elf/contact-us`} className="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
