import React from "react";
import { Link } from 'gatsby'
import appData from "data/app.json";

const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="item md-mb50">
              <div className="title">
                <h5>Experience Centers</h5>
              </div>
              <ul>
                <li>
                  <a href="https://maps.app.goo.gl/nKeVzhAmVBYVpibm9" target="_blank" rel="noreferrer">
                  <span className="icon pe-7s-map-marker"></span>
                    </a>
                  <div className="cont">
                    <h6>Bengaluru, INDIA </h6>
                    <p>40/1A, Lavelle Road, Bengaluru, Karnataka - 560001 </p>
                    <h6>Denver, US </h6>
                    <p>6295 Greenwood Plaza Blvd, Ste#100, Denver - 80111  </p>
                    <h6>Bhubaneswar, INDIA</h6>
                    <p>6th Floor, STPI ELITE Tower, Bhubaneswar - 751003 </p>
                    <h6>Pune, INDIA </h6>
                    <p>H-1030, Solitaire Business Hub, Viman Nagar, Pune - 411014</p>
                    <h6>Hyderabad, INDIA </h6>
                    <p>No. 14, Silicon Towers, Kondapur, Hyderabad - 500032</p>

                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-mail"></span>
                  <div className="cont">
                    <h6>Email</h6>
                    <p>info@elfonze.com</p>
                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-call"></span>
                  <div className="cont">
                    <h6>Let's Talk</h6>
                    <p>+91 9663396810 / +1 720 363 3526</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Recent News</h5>
              </div>
              <ul>
                <li>
                  <div className="img">
                    <Link to="/blog-details/blog-details-dark">
                      <img src="/img/blog/1.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="sm-post">
                    <Link to="/blog-details/blog-details-dark">
                      <p>
                        The Start-Up Ultimate Guide to Make Your WordPress
                        Journal.
                      </p>
                    </Link>
                    <Link to="/blog/blog-dark">
                      <span className="date">14 sep 2022</span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <Link to="/blog-details/blog-details-dark">
                      <img src="/img/blog/2.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="sm-post">
                    <Link to="/blog-details/blog-details-dark">
                      <p>
                        The Start-Up Ultimate Guide to Make Your WordPress
                        Journal.
                      </p>
                    </Link>
                    <Link to="/blog/blog-dark">
                      <span className="date">14 sep 2022</span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="subscribe">
                    <input type="text" placeholder="Type Your Email" />
                    <span className="subs pe-7s-paper-plane"></span>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-lg-4">
            <div className="item">
              <div className="logo">
                <img src={appData.lightLogo} alt="" />
              </div>
              <div className="social">
              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                  <i className="fab fa-youtube"></i>
                </a>
              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href=" https://t.me/elfonze_technologies" target="_blank" rel="noreferrer">
                  <i className="fab fa-telegram"></i>
                </a>
                <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                  <i className="fab fa-quora"></i>
                </a>
                <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="15px" height="18px" fill="#ffffff" className="twitter_x"><path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z"/></svg>
                </a>
              </div>
              <br></br>
              <div className="copy-right">
                <p>
                  © 2024, Elfonze Technologies
                  <Link to="#" target="_blank">
                  In a world of Quantity, <br />We offer Quality
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
